import React, { Component } from 'react'
import Typewriter from 'typewriter-effect';

export default class Greetings extends Component {

    render() {
        return (
            <div className="greetings-effect">
                <Typewriter
                    options={{
                        delay: 40, 
                        autoStart: true,
                        loop: true,
                    }}
                    onInit={(typewriter) => {
                        typewriter.typeString('I design and deliver products with highest possible value.')
                        .pauseFor(1500)
                        .deleteChars(56)
                        .typeString('develop apps Java.')
                        .pauseFor(1500)
                        .deleteChars(5)
                        .typeString('Python.')
                        .pauseFor(1500)
                        .deleteChars(7)
                        .typeString('NodeJS.')
                        .pauseFor(1500)
                        .deleteChars(7)
                        .typeString('ReactJS.')
                        .pauseFor(1500)
                        .deleteChars(8)
                        .typeString('Angular.')
                        .start();
                    }}
                />
            </div>
        )
    }
}

import React, { Component } from 'react'
import { Button } from 'react-bootstrap';

import bgPurple from '../resources/bg-purple.png'

export default class TechCard extends Component {

    render() {
        return (
            <div className="contact">
                <div className="bg-purple-container mt-5">
                    <img src={bgPurple} alt="background typing"></img>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="contact-details">
                                    <p>email me: </p>
                                    <h1>juliusgutierrez@ymail.com</h1>
                                </div>
                            </div>
                            <div className="col">
                                <div className="project-details" align="center">
                                    <h4>{`Let's start a project`}</h4>
                                    <p>{`Interested in working together? Lets talk about ideas bringing to life.`}</p>
                                    <p>{`I’ll buy the coffee.`}</p>
                                    <Button variant="primary" size="lg" className="purple-button" onClick={this.props.onClick}>{`Let's do this`}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        )
    }
}
import 'bootstrap/dist/css/bootstrap.css';

import Home from './component/Home';

import './App.css';

function App() {
    return (
      <>
        <Home/>
      </>
  );
}

export default App;

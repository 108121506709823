import React, { Component } from 'react'

import myicon from '../icons/my_logo.svg'


import Greetings from './Greetings'
import { Button } from 'react-bootstrap';

export default class Header extends Component {

    render() {
        return (
            <div className="container header">
                <div className="row mt-5">
                    <div className="col" align="center">
                        <img src={myicon} alt="Julius Gutierrez"></img>
                    </div>
                    <div className="col"></div>
                    <div className="col"></div>
                    <div className="col mt-2 purple-button-container" align="center">
                        <Button variant="primary" size="lg" className="purple-button" onClick={this.props.onClick}>Message Me!</Button>
                    </div>
                </div>
                <div className="row greetings mt-5">
                    <div className="d-flex justify-content-left">
                        <p>{`Tech Lead, Full stack Developer & Professional Scrum Master I`}</p>
                    </div>
                    <div>
                        <Greetings></Greetings>
                    </div>
                </div>
            </div>
        )
    }
}
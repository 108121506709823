import React from 'react';
import {Modal} from 'react-bootstrap'

export default class ResponseModal extends React.Component {

    constructor(props) {
        super(props);
        
        this.handleClose = this.handleClose.bind(this);    
        this.showSuccessModal = this.showSuccessModal.bind(this);

        this.state = {
            show:false
        }

        this.state.show = this.props.showModal;
    }

    handleClose() {
        this.setState({show:false})
        this.props.handler()
    }

    showSuccessModal() {
        this.setState({show:this.props.showModal})
        return this.state.show;
    }


    
    render () {
        let {errorMessage} = this.props
        let message = `Email Sent!`
        if (errorMessage !== undefined) {
            message = errorMessage    
        }

        return (
            <div>
                <Modal show={this.state.show} onHide={this.handleClose} 
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    className={`responseModal ` + this.props.color}
                >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body align="center">
                    <i className={this.props.icon}></i>
                    <p>{message}</p>
                </Modal.Body>
                </Modal>

            </div>
        )
    }
}
import React, { Component } from 'react'


import logowhite from '../icons/my_logo_white.svg'

export default class Sig extends Component {
    
    
    render() {
        return (
            <div className="diagonal-box">
                <div className="content" align="center">
                    <img className="mt-5" src={logowhite} alt="fe"></img>
                    <p className="qoutes mt-4">{`Learn, Change, & Accept Challenge`}</p>
                    <p className="">{`There's always one thing to learn everyday`}</p>
                    <div className="icons">
                        <a href="https://www.linkedin.com/in/julius-gutierrez-psm-l-47302298/" target="_blank" rel="noreferrer">
                            <i className="icon ion-logo-linkedin"></i>
                        </a>

                        <a href="https://www.facebook.com/usegutierrez" target="_blank" rel="noreferrer">
                            <i className="icon ion-logo-facebook"></i>
                        </a>
                        <a href="https://github.com/juliusgutierrez" target="_blank" rel="noreferrer">
                            <i className="icon ion-logo-github"></i>
                        </a>
                    </div>
                    <footer>
                        <div align="center">
                            <p>Copyright &copy; 2018 by Julius Gutierrez. All right reserved.</p>
                        </div>
                    </footer>
                </div>
            </div>
        )
    }
}
import React, { Component } from 'react'
import FadeIn from 'react-fade-in';

import video from '../video/web-development.mp4'


export default class Header extends Component {

    render() {
        return (
            <div className="bg-purple">
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-sm">
                            <FadeIn delay={500}>
                                <div className="m-5">
                                    <p className="text-white">{`Hi, I’m Julius. Nice to meet you.`}</p>
                                    <p className="text-white">{`I am an experienced Fullstack developer with years of experience in software development. currently living in Manila, Philippines. I have designed and implemented efficient applications and programs in various domain like financials, banking, e-commerce and analytics.`}</p>
                                    <p className="text-white">{`I'm available for remote work — if you like to build something, just contact me.`}</p>
                                </div>    
                            </FadeIn>
                        </div>
                        <div className="col-sm intro-video-web" align="center">
                            <video height="300" autoPlay={true} loop muted>
                                <source src={video} type="video/mp4"/>
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
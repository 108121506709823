import React, { Component } from 'react'
import FadeIn from 'react-fade-in';

import TechCard from './TechCard'

import feicon from '../icons/fe-icon.svg'
import flowcharticon from '../icons/fc.svg'
import cloudgearicon from '../icons/cloudgear.svg'


export default class Main extends Component {

    render() {
        return (
            <div className="container main" align="center">
                <div className="row mt-5">
                    <div className="d-flex flex-sm-row flex-column" >
                        <div className="mr-auto p-2">
                            <FadeIn delay={500}>
                                <TechCard 
                                    icon={feicon}
                                    title={`Front End Developer`}
                                    titleDescription = {`I like coding from scratch and brings new ideas in the browser`}
                                    techStack = {`TechStack I use:`}
                                    techStackDetails = {`JS, ReactJS, Angular 1 & 2, HTML, CSS, Sass`}
                                    designTools = {`Design Tools`}
                                    designToolsList = {[`Figma`, `Draw.io`]}
                                />       
                            </FadeIn>
                        </div>
                        <div className="p-2">
                            <FadeIn delay={500}>
                                <TechCard 
                                        icon={flowcharticon}
                                        title={`Back End Developer`}
                                        titleDescription = {`I value best coding practices and clean design patterns`}
                                        techStack = {`Language I speak:`}
                                        techStackDetails = {`Java 8, NodeJS, Python 3`}
                                        designTools = {`Dev Tools`}
                                        designToolsList = {[`IntelliJ`, `Eclipse`, `VS Code`]}
                                    />
                            </FadeIn>
                        </div>
                        <div className="p-2">
                            <FadeIn delay={500}>
                                <TechCard 
                                    icon={cloudgearicon}
                                    title={`DevOps`}
                                    titleDescription = {`Deploying the ideas in the internet is l ike bringing it to life`}
                                    techStack = {`Deployment tools I prefer:`}
                                    techStackDetails = {`Docker, Kubernetes, Maven, Gradle, Git, Jenkins`}
                                    designTools = {`Other tools I use`}
                                    designToolsList = {[`BitBucket`, `Nginx`, `AWS (EKS, ECS, S3, Lambda, CloudWatch)`, `Azure`, `Google Cloud`]}
                                />
                            </FadeIn>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
import React, { Component } from 'react'
import { Card } from 'react-bootstrap'

export default class TechCard extends Component {

    renderToolList = (designToolsList) => {
        if (designToolsList) {
            return designToolsList.map(tool => (
                <p key={tool}>{tool}</p>
            ))
        }
    }

    render() {
        const { icon, title, titleDescription, techStack, techStackDetails, designTools, designToolsList } = this.props
        return (
            <Card align="center">
                <Card.Body>
                    <img className="mt-5" src={icon} alt="fe"></img>
                    <h3 className="mt-5">{title}</h3>
                    <p className="mt-5" align="center">{titleDescription}</p>

                    <h4 className="mt-5">{techStack}</h4>
                    <p className="mt-4">{techStackDetails}</p>

                    <h5 className="mb-4">{designTools}</h5>
                    {this.renderToolList(designToolsList)}
                </Card.Body>
            </Card>
        )
    }
}
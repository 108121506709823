import React from 'react';
import {Modal, Button, Form, Row, Col} from 'react-bootstrap'
import Cookies from 'universal-cookie'

import myicon from '../icons/my_logo.svg'
import { send } from 'emailjs-com';
import ResponseModal from './ResponseModal';

export default class MessageModal extends React.Component {

    constructor(props) {
        super(props);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.onChangeSelect = this.onChangeSelect.bind(this);
        this.handler = this.handler.bind(this);

        this.state = {
            show:false,
            name:'',
            email:'',
            type:'Web',
            budget:'35,000',
            message: '',
            showSuccessModal: false,
            showFailedModal: false,
            errorMessage: '',
            validated: false,
            errorName: false,
            errorEmail: false,
            invalidEmail : false,
        }

        
    }


    handleShow() {
        this.setState({show:true})
    }

    handleClose() {
        this.setState({show:false})
    }

    sucessRef = (obj) => {
        this.showModal = obj && obj.handleShow;
    }

    onSubmit = () => {
        let {name, email, type, budget, message} = this.state;
        let toSend = {
            name: name,
            email: email,
            type: type,
            budget: budget,
            message: message
        }

        this.validateForm()
        
        let {invalidEmail} = this.state;
        let isValid = (name) && (email) && invalidEmail === false;
        
        let cookies = new Cookies();
        if (cookies.get('juliusgutierrez') === undefined && isValid) {
            
            let nextMonth = 1000000;
            cookies.set('juliusgutierrez', 'true', { 
                path: '/', 
                maxAge: nextMonth
            });
            send(
                '0H5vtUDq*t$8',
                'template_6gkreab',
                toSend,
                'user_e5CRfFNgArsxYSHaycCoN'
                ).then((response) => {
                  this.handleClose()
                  this.setState({showSuccessModal: true, showFailedModal: false})
                }).catch((err) => {
                  this.handleClose()
                    this.setState({
                        showSuccessModal: false, 
                        showFailedModal: true, 
                        errorMessage: `Error Sending an email, Please try again later`
                    })

                });
        } else if (isValid && cookies.get('juliusgutierrez')) {
            
            this.handleClose()
            this.setState({
                showSuccessModal: false, 
                showFailedModal: true, 
                errorMessage: `You already send an email before, Please wait for the reply`
            })
        }
    }

    validateForm = () => {
        let {name, email} = this.state;

        if (!name) {
            this.setState({errorName : true})
        } else {
            this.setState({errorName : false})
        }
        if (!email) {
            this.setState({errorEmail : true})
        } else {
            this.setState({errorEmail : false})
        }

        if (email) {
            this.validateEmail(email);
        }
    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const isValid = re.test(String(email).toLowerCase());
        if (!isValid) {
            this.setState({invalidEmail: true})
        } else {
            this.setState({invalidEmail: false})
        }
    }

    onChangeSelect = (key, e) => {
        this.setState({[key]: e.target.value})
    }

    handleChange = (key, e) => {
        this.setState({[key]: e.target.value});
    }

    handler() {
        this.setState({showSuccessModal:false, showFailedModal: false});
    }

    render() {
        return (
            <>
                <Modal show={this.state.show} onHide={this.handleClose} 
                    backdrop="static" keyboard={false}
                    centered={true}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    size="lg"
                    
                >
                    <Modal.Header closeButton>
                        <div>
                            <img src={myicon} alt="Julius Gutierrez"></img>        
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        
                        <div className="container">
                            <div className="row">
                                <div className="mb-3">
                                    <h3>{`I’m excited to learn about your project. Lets make it happen`}</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Form noValidate validated={this.state.validated} >
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="formName">
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control type="text" placeholder="Enter name" 
                                                    isInvalid={this.state.errorName}
                                                    value={this.state.name} onChange={(e) => this.handleChange(`name`, e)}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please input a name.
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="formEmail">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control type="email" placeholder="Email"
                                                    isInvalid={this.state.errorEmail || this.state.invalidEmail}
                                                    value={this.state.email} onChange={(e) => this.handleChange(`email`, e)}
                                                />
                                                {
                                                    this.state.errorEmail &&
                                                    <Form.Control.Feedback type="invalid">
                                                        Please input an email.
                                                    </Form.Control.Feedback>
                                                }

                                                {
                                                    this.state.invalidEmail &&
                                                    <Form.Control.Feedback type="invalid">
                                                        Invalid email format.
                                                    </Form.Control.Feedback>
                                                }
                                                
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="formType">
                                                <Form.Label>Type of project</Form.Label>
                                                <Form.Select onChange={(e) => this.onChangeSelect(`type`, e)}>
                                                    <option>Web</option>
                                                    <option>Mobile</option>
                                                    <option>Others</option>
                                                </Form.Select>  
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formBudget">
                                                <Form.Label>Budget (PHP)</Form.Label>
                                                <Form.Select onChange={(e) => this.onChangeSelect(`budget`, e)}>
                                                    <option>35,000</option>
                                                    <option>50,000</option>
                                                    <option>100,000</option>
                                                    <option>Negotiate</option>
                                                </Form.Select>  
                                            </Form.Group>
                                        </Row>
                                        <Form.Group className="mb-3" controlId="formAdditional">
                                            <Form.Label>Additional Details</Form.Label>
                                            <Form.Control as="textarea" rows={3} 
                                                value={this.state.message} onChange={(e) => this.handleChange(`message`, e)}
                                            />
                                        </Form.Group>
                                        <div className="" align="right">
                                            <Button variant="primary" type="button" onClick={this.onSubmit} className="purple-button">Submit</Button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {
                  this.state.showSuccessModal
                  && <ResponseModal showModal={true} icon={`response-modal-icon icon-green ion-ios-checkmark-circle`} 
                        color="green" handler={this.handler}/>
                }

                {
                  this.state.showFailedModal
                  && <ResponseModal showModal={true} 
                    icon={`response-modal-icon icon-red ion-ios-close-circle`} 
                    handler={this.handler} color="red"
                    errorMessage={this.state.errorMessage}
                    />
                }   
            </>
        )
    }

}
import React, { Component } from 'react'
import Headers from './Header';
import Main from './Main';
import Introduction from './Introduction';
import Contact from './Contact'
import Sig from './Sig';
import MessageModal from './MessageModal';

export default class Home extends Component {

    modalRef = (obj) => {
        this.showModal = obj && obj.handleShow;
    }
      
    onMessageTrigger = () => {
        this.showModal();
    }

    render() {
        return (
            <div>
                <div>
                    <Headers onClick={this.onMessageTrigger}/>
                </div>
                <div>
                    <Introduction/>
                </div>
                <div>
                    <Main/>
                </div>
                <div>
                    <Contact onClick={this.onMessageTrigger}/>
                </div>
                <div>
                    <Sig/>
                </div>
                <MessageModal ref={this.modalRef}/>
            </div>

        ) 
    }
}